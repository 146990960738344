import axios from 'axios';
//import { aws4Interceptor } from "aws4-axios";
const apigClientFactory = require('aws-api-gateway-client').default;
//const aws4  = require('aws4')
const parseURL = require("url-parse");

export default class Endpoint {
  readonly base_api_url: string | undefined;
  readonly region: string | undefined;
  readonly accessKey: string | undefined;
  readonly secretKey: string | undefined;

  constructor(...args: [] | [base_api_url: string | null | undefined, region: string | null | undefined, accessKey: string | null | undefined, secretKey: string | null | undefined]) {
    this.base_api_url = args[0] || process.env.REACT_APP_BASE_API_URL;
    this.region = args[1] || process.env.REACT_APP_REGION;
    this.accessKey = args[2] || process.env.REACT_APP_KEY;
    this.secretKey = args[3] || process.env.REACT_APP_ACCESS;
  }
  get = (path: string) => {
    return this.getApiClient(path);
  };
  getExternal = (path: string) => {
    return axios.get(path);
  };
  getOptions = (path: string, options: any) => {
    return axios.get(path, options);
  };
  getMulti = (paths: string) => {
    let request = [];
    for (const path of paths) {
      request.push(this.getApiClient(path));
    }
    return axios.all(request).then(
      axios.spread((...data) => {
        return data;
      })
    );
  };
  getWithAdditionalParams = (path: string, additionalParams: any) => {
    return this.getApiClientWithAdditionalParams(path, additionalParams);
  };
  post = (path: string, req: any) => {
    return this.postApiClient(path, req);
  };
  postWithAdditionalParams = (
    path: string,
    req: any,
    additionalParams: any
  ) => {
    return this.postApiClientWithAdditionalParams(path, req, additionalParams);
  };
  postExternal = (path: string, req: any) => {
    return axios.post(path, req);
  };
  postOptions = (path: string, req: any, options: any) => {
    return axios.post(path, req, options);
  };
  putOptions = (path: string, req: any, options: any) => {
    return axios.put(path, req, options);
  };
  generateApiClient = (
    invokeUrl: string | undefined,
    region: string | undefined,
    path: string,
    method: string
  ) => {
    var apigClient = apigClientFactory.newClient({
      invokeUrl,
      region,
      accessKey: this.accessKey,
      secretKey: this.secretKey,
    });
    return {
      apigClient,
      args: {
        pathParams: {},
        pathTemplate: parseURL(path).pathname,
        method,
        additionalParams: {
          headers: {
            //"Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Allow-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization",
            //Origin: process.env.REACT_APP_BASE_ACCEPT,
            //"Access-Control-Allow-Origin": process.env.REACT_APP_BASE_ACCEPT,
            //Accept: process.env.REACT_APP_BASE_ACCEPT
          },
          queryParams: {},
        },
        body: "",
      },
    };
  };
  generateApiClientWithAdditionalParams = (
    invokeUrl: string | undefined,
    region: string | undefined,
    path: string,
    method: string,
    additionalParams: any
  ) => {
    var apigClient = apigClientFactory.newClient({
      invokeUrl,
      region,
      accessKey: this.accessKey,
      secretKey: this.secretKey,
    });
    return {
      apigClient,
      args: {
        pathParams: {},
        pathTemplate: parseURL(path).pathname,
        method,
        additionalParams,
        body: "",
      },
    };
  };
  getApiClient = async (path: string) => {
    const resp = this.generateApiClient(
      this.base_api_url,
      this.region,
      path,
      "GET"
    );
    return await resp.apigClient.invokeApi(
      resp.args.pathParams,
      resp.args.pathTemplate,
      resp.args.method,
      resp.args.additionalParams,
      resp.args.body
    );
  };
  getApiClientWithAdditionalParams = async (
    path: string,
    additionalParams: any
  ) => {
    const resp = this.generateApiClientWithAdditionalParams(
      this.base_api_url,
      this.region,
      path,
      "GET",
      additionalParams
    );
    return await resp.apigClient.invokeApi(
      resp.args.pathParams,
      resp.args.pathTemplate,
      resp.args.method,
      resp.args.additionalParams,
      resp.args.body
    );
  };
  postApiClient = async (path: string, req: any) => {
    const resp = this.generateApiClient(
      this.base_api_url,
      this.region,
      path,
      "POST"
    );
    return await resp.apigClient.invokeApi(
      resp.args.pathParams,
      resp.args.pathTemplate,
      resp.args.method,
      resp.args.additionalParams,
      req
    );
  };
  postApiClientWithAdditionalParams = async (
    path: string,
    req: any,
    additionalParams: any
  ) => {
    const resp = this.generateApiClientWithAdditionalParams(
      this.base_api_url,
      this.region,
      path,
      "POST",
      additionalParams
    );
    return await resp.apigClient.invokeApi(
      resp.args.pathParams,
      resp.args.pathTemplate,
      resp.args.method,
      resp.args.additionalParams,
      req
    );
  };
  patchApiClientWithAdditionalParams = async (
    path: string,
    req: any,
    additionalParams: any
  ) => {
    const resp = this.generateApiClientWithAdditionalParams(
      this.base_api_url,
      this.region,
      path,
      "PATCH",
      additionalParams
    );
    return await resp.apigClient.invokeApi(
      resp.args.pathParams,
      resp.args.pathTemplate,
      resp.args.method,
      resp.args.additionalParams,
      req
    );
  };
  deleteApiClient = async (path: string) => {
    const resp = this.generateApiClient(
      this.base_api_url,
      this.region,
      path,
      "DELETE"
    );
    return await resp.apigClient.invokeApi(
      resp.args.pathParams,
      resp.args.pathTemplate,
      resp.args.method,
      resp.args.additionalParams,
      resp.args.body
    );
  };
}