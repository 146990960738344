import Endpoint from "./Endpoint";
import Developer from "../interfaces/Developer";
import Venue from "interfaces/Venue";
export interface Backpack {
  address?:string
  chain?: string;
  backpacUUID?: string;
  chainIssuer?: string;
  created?: string;
  dt?: string;
  hash?: string;
  isoDate?: string;
  issuer?: string;
  updatedAt?: string;
  week?: string;
}


export default class Company {
  state: string;
  companyId: string | null;
  org_Id: string | null;
  name: string | null;
  email: string | null;
  description: string | null;
  website: string | null;
  twitter: string | null;
  facebook: string | null;
  imgUrl: string | null;
  logo: string | null;
  backgroundImage: string | null;
  status: boolean;
  companyuuid: string | null;
  developer: Developer | null;
  timeStamp: string | null;
  isoDate: string | null;
  week: number | null;
  created: string | null;
  updatedAt: string | null;
  backpacks: Array<Backpack> | null;
  venues: Array<Venue> | [];

  constructor(
    state: string,
    companyId: string | null,
    org_Id: string | null,
    name: string | null,
    email: string | null,
    description: string | null,
    website: string | null,
    twitter: string | null,
    facebook: string | null,
    imgUrl: string | null,
    logo: string | null,
    backgroundImage: string | null,
    developer: Developer | null
  ) {
    this.state = state;
    this.companyId = companyId || null;
    this.org_Id = org_Id || null;

    this.name = name || null;
    this.email = email || null;

    this.description = description || null;
    this.website = website || null;

    this.twitter = twitter || null;
    this.facebook = facebook || null;

    this.imgUrl = imgUrl || null;

    this.logo = logo || null;

    this.backgroundImage = backgroundImage || null;

    this.developer = developer || null;

    this.companyuuid = null;
    this.timeStamp = null;
    this.isoDate = null;
    this.week = null;
    this.created = null;
    this.updatedAt = null;

    this.backpacks = [];

    this.status = true;

    this.venues = [];
  }

  get = async (did_token: string | undefined) => {
    const _Endpoint = new Endpoint();
    const company = await _Endpoint.getWithAdditionalParams(`/console/company/${this.state}/${this.companyId}/${this.org_Id}`,      {
      headers: { did_token, platform: "console" },
    });
    return company.data;
  };
  create = async (did_token: string) => {
    const _Endpoint = new Endpoint();
    const create = await _Endpoint.postApiClientWithAdditionalParams(
      "/company",
      this,
      {
        headers: { did_token, platform: "console" },
      }
    );
    console.log(create)
  };
  update = async (did_token: string) => {
    const _Endpoint = new Endpoint();
    const create = await _Endpoint.patchApiClientWithAdditionalParams(
      "/company",
      this,
      {
        headers: { did_token, platform: "console" },
      }
    );
    console.log(create)
  };
  delete = () => {};
  exists = () => {};
}