import React, { FC, useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
//import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import authorBanner from "images/nfts/authorBanner.png";
import DashboardDropdown from "components/DashboardDropdown";
//import SectionGridDashboardBox from "components/SectionGridDashboardBox/SectionGridDashboardBox";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast, ToastOptions, Id } from "react-toastify";
import Company from "model/Company";
//import { Tab } from "@headlessui/react";
//import CardVenueBox from "components/CardVenueBox/CardVenueBox";
//import CardEventBox from "components/CardEventBox/CardEventBox";
import { RadioGroup } from "@headlessui/react";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
//import DashboardVenueButton from "components/DashboardVenueButton";
import DiscountSVG from "images/pos/discount-svgrepo-com-color.svg";
import priceTagSVG from "images/pos/price-tag-svgrepo-com.svg";
import StatusImageIcon from "components/StatusImageIcon";
import SquareLogoSVG from "images/pos/square-logo-bold-svgrepo-com.svg";
import Heading from "components/Heading/Heading";
import CardNFT from "components/CardNFTORG";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";

//import ToastLogoSVG from "images/pos/TOST.svg"
import "react-toastify/dist/ReactToastify.css";
export interface DashboardPageProps {
  className?: string;
}

const plans = [
  {
    id: "8c5e4b06-f30d-4fda-9127-034865625ff5",
    points: 33,
    featuredImage: DiscountSVG,
    name: "33% off entire sale (up to $30.00 off)",
    definition: {
      scope: "ORDER",
      discountType: "FIXED_PERCENTAGE",
      percentageDiscount: "33",
      maxDiscountMoney: {
        amount: 3000,
        currency: "USD",
      },
    },
    createdAt: "2023-04-22T04:01:46Z",
    pricingRuleReference: {
      objectId: "Z2S2O45IVJY6ZGVFROFWCF4L",
      catalogVersion: 1682136106007,
    },
  },
  {
    id: "90287cb8-147f-4fe1-9d95-c37e1d581a26",
    points: 50,
    featuredImage: priceTagSVG,
    name: "$50.00 off one Teeth Cleaning & Whitening",
    definition: {
      scope: "ITEM_VARIATION",
      discountType: "FIXED_AMOUNT",
      catalogObjectIds: ["MNRXA3TX7SIUQIVZDFPQWN5H"],
      fixedDiscountMoney: {
        amount: 5000,
        currency: "USD",
      },
    },
    createdAt: "2023-04-27T20:47:49Z",
    pricingRuleReference: {
      objectId: "5RPXVLSJFVYVGP7SMTNEN4QQ",
      catalogVersion: 1682628468804,
    },
  },
  {
    id: "9ce708d5-2d68-4bd2-af75-b3e12cc35cbd",
    points: 100,
    featuredImage: DiscountSVG,
    name: "A free Teeth Cleaning & Whitening",
    definition: {
      scope: "ITEM_VARIATION",
      discountType: "FIXED_PERCENTAGE",
      percentageDiscount: "100",
      catalogObjectIds: ["MNRXA3TX7SIUQIVZDFPQWN5H"],
    },
    createdAt: "2023-04-27T00:25:52Z",
    pricingRuleReference: {
      objectId: "KIV6S22TYN2UGBQ2W5UYMHTZ",
      catalogVersion: 1682555152156,
    },
  },
  {
    id: "fe5ea06c-da95-4124-b37a-e687a91e4d18",
    points: 100,
    featuredImage: DiscountSVG,
    name: "Free Item",
    definition: {
      scope: "ITEM_VARIATION",
      discountType: "FIXED_PERCENTAGE",
      percentageDiscount: "100",
      catalogObjectIds: ["MNRXA3TX7SIUQIVZDFPQWN5H"],
    },
    createdAt: "2023-04-27T20:47:49Z",
    pricingRuleReference: {
      objectId: "7PZZY6LE4B6EGYDM4AQUIMJM",
      catalogVersion: 1682628468738,
    },
  },
];

const DashboardPage: FC<DashboardPageProps> = ({ className = "" }) => {
  const { user, getIdTokenClaims } = useAuth0();
  const [loading, setLoading] = useState<boolean>(true);
  //const [disabled, setDisabled] = useState<boolean>(false);
  const [company, setCompany] = useState<Company | null>(null);
  const [selected, setSelected] = useState(plans[1]);
  const [isVerifiedCreator, setIsVerifiedCreator] = useState(true);
  // let [categories] = useState([
  //   "Memberships",
  //   "Venues",
  //   "Events",
  // ]);

  const toastConfig: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    type: "info",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  useEffect(() => {}, [company]);

  useEffect(() => {
    if (user !== null && typeof user !== "undefined") {
      const companyId =
        user[process.env.REACT_APP_AUTH0_USER_METADATA || ""].companyId;
      const state = user[process.env.REACT_APP_AUTH0_USER_METADATA || ""].state;
      const org_id = user.org_id;
      getCompany(companyId, state, org_id);
      //console.log(org_id)
      //getToken();
    }
  }, [user]);

  //  const getToken = async ()=>{
  //   const claims = await getIdTokenClaims();
  //   console.log(claims?.__raw)
  // };

  const getCompany = async (
    companyId: string,
    state: string,
    orgId: string
  ) => {
    setLoading(true);

    const claims = await getIdTokenClaims();
    const did_token = claims?.__raw;

    const Id: Id = toast.loading("Loading Dashboard", toastConfig);

    const company = new Company(
      state,
      companyId,
      orgId,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    const _company = await company.get(did_token);
    //console.log(_company.data.venues)
    setCompany(_company.data);

    toast.update(Id, {
      render: "loaded",
      type: "info",
      isLoading: false,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    toast.dismiss(Id);

    setLoading(false);
  };

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabLocations = () => {
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer  ${
          isVerifiedCreator
            ? "border-primary-500 bg-primary-50 text-primary-900"
            : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
        }`}
        onClick={() => setIsVerifiedCreator(!isVerifiedCreator)}
      >
        <svg
          className="w-4 h-4"
          fill="#000000"
          height="800px"
          width="800px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 297 297"
          xmlSpace="preserve"
        >
          <path
            d="M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645
	c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645
	C259.253,49.703,209.57,0,148.5,0z M148.5,79.693c16.964,0,30.765,13.953,30.765,31.104c0,17.151-13.801,31.104-30.765,31.104
	c-16.964,0-30.765-13.953-30.765-31.104C117.735,93.646,131.536,79.693,148.5,79.693z"
          />
        </svg>

        <span className="line-clamp-1 ml-2">Locations</span>
        {isVerifiedCreator && renderXClear()}
      </div>
    );
  };

  const renderTabsAddMembership = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none `}
            >
              <svg
                fill="#000000"
                className="w-4 h-4"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="800px"
                height="800px"
                viewBox="0 0 256 178"
                enableBackground="new 0 0 256 178"
                xmlSpace="preserve"
              >
                <path
                  d="M183.882,38.248c0,8.995,7.292,16.287,16.287,16.287c8.995,0,16.287-7.292,16.287-16.287s-7.292-16.287-16.287-16.287
	C191.174,21.961,183.882,29.253,183.882,38.248z M167.193,92.774v-12.72c0-11.179,9.126-20.349,20.349-20.349h26.642
	c11.179,0,20.349,9.126,20.349,20.349v12.72H167.193z M95.721,2.416v23.543c-2.871,0-8.236,0.615-12.1,1.784
	c-10.322,3.123-19.031,10.11-24.369,21.786L2,175.584h78.75l5.069-16.893c22.193-5.386,38.809-22.446,45.51-43.894l0.084,0.001
	c0.167-0.748,0.389-1.483,0.627-2.214H254V2.416H95.721z M246.125,104.709H115.408c-2.957,15.124-14.146,28.884-29.224,35.043
	c-0.487,0.199-0.992,0.293-1.488,0.293c-1.552,0-3.023-0.924-3.647-2.449c-0.822-2.013,0.143-4.311,2.156-5.134
	c14.768-6.032,25.085-20.66,25.105-35.583v-3.981h40.386c5.966-0.001,10.803-4.837,10.803-10.803c0-5.962-4.83-10.797-10.793-10.802
	l-70.91-0.053c-2.174,0-3.938-1.763-3.938-3.938s1.763-3.938,3.938-3.938h25.799V10.291h142.529V104.709z M115.408,25.959H159.5
	v7.875h-44.092V25.959z M115.408,45.647H159.5v7.875h-44.092V45.647z"
                />
              </svg>

              <span className="ml-2">{`Add Membership to Program`}</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <Label key={"name"}>Membership Name</Label>
                      <Input
                        name="name"
                        className="mt-1.5"
                        value={""}
                        disabled={loading}
                        onChange={(e) => {}}
                      />
                    </div>
                    <div className="space-y-5">
                      <Label key={"imageUrl"}>Image URL</Label>
                      <Input
                        name="imageUrl"
                        className="mt-1.5"
                        value={""}
                        disabled={loading}
                        onChange={(e) => {}}
                      />
                    </div>
                    <div className="space-y-5">
                      <Label>Description</Label>
                      <Textarea
                        name="description"
                        rows={5}
                        className="mt-1.5"
                        placeholder="Something about the company in a few word."
                        value={""}
                        onChange={(e) => {}}
                      />
                    </div>
                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max Supply
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <Input
                            name="supply"
                            id="supply"
                            className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                            value={""}
                            disabled={loading}
                            onChange={(e) => {}}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Symbol
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <Input
                            name="symbol"
                            id="symbol"
                            className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                            value={""}
                            disabled={loading}
                            onChange={(e) => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Create
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  return (
    <div
      className={`nc-DashboardPage  ${className}`}
      data-nc-id="DashboardPage"
    >
      <Helmet>
        <title>Backpac Console Dashboard</title>
        <link
          href="https://unpkg.com/css.gg@2.0.0/icons/css/square.css"
          rel="stylesheet"
        ></link>
      </Helmet>

      {/* HEADER */}
      <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            containerClassName="absolute inset-0"
            src={company?.backgroundImage || authorBanner}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="relative container -mt-14 lg:-mt-20">
          <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row lg:items-center">
            <div className="flex flex-col sm:flex-row md:block sm:items-start sm:justify-between">
              <div className="w-40 sm:w-48 md:w-56 xl:w-60">
                <div className="relative flex-shrink-0 ">
                  <div>
                    {/* src={ToastLogoSVG || SquareLogoSVG} */}
                    <NcImage
                      containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden"
                      src={SquareLogoSVG}
                      className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                    />
                  </div>
                  <StatusImageIcon
                    className="absolute top-3 left-3 !w-9 !h-9"
                    active={true}
                  />
                  <div className="absolute top-3 inset-x-3 flex"></div>
                </div>
              </div>
              <div className="mt-4 flex items-center sm:justify-center space-x-3">
                <div className="flex space-x-1.5 text-neutral-700 dark:text-neutral-300">
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      toast("Square POS currently displayed", {
                        type: "info",
                        isLoading: false,
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    }}
                    title="Select Square POS"
                    aria-label="Select Square POS"
                    className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-green-100 hover:bg-green-200 dark:hover:bg-neutral-700 dark:bg-green-800 cursor-pointer"
                  >
                    <i className="text-base sm:text-xl lab gg-square"></i>
                  </Link>
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      toast("Toast POS not Connected", {
                        type: "warning",
                        isLoading: false,
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    }}
                    title="Select Toast POS"
                    aria-label="Select Toast POS"
                    className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                  >
                    <i className="text-base sm:text-xl las la-bread-slice"></i>
                  </Link>
                </div>
                <div className="h-5 border-l border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex space-x-1.5">
                  <DashboardDropdown
                    actions={[
                      {
                        id: "edit",
                        name: "Point of Sale",
                        href: "/point-of-sale",
                        icon: "las la-cash-register",
                        data: {},
                      },
                      {
                        id: "edit",
                        name: "Square Program",
                        href: "/dashboard?pos=square",
                        icon: "las la-square",
                        data: {},
                      },
                      {
                        id: "edit",
                        name: "Toast Program",
                        href: "/dashboard?pos=toast",
                        icon: "las la-bread-slice",
                        data: {},
                      },
                    ]}
                    containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:ml-8 xl:ml-14 flex-grow">
              <div className="max-w-screen-sm ">
                <h2 className="inline-block text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  {company?.name}
                </h2>
                <span className="block mt-4 text-sm text-neutral-500 dark:text-neutral-400">
                  {company?.description}
                </span>
              </div>
              <div className="mt-6 xl:mt-8 grid grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4 xl:gap-6">
                {/* ----- 1 ----- */}
                <div className="rounded-2xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    Accrual Type
                  </span>
                  <span className="font-medium text-base mt-4 sm:text-xl sm:mt-6">
                    Spend
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    4 Reward Tiers
                  </span>
                </div>

                {/* ----- Volume ----- */}
                <div className="rounded-2xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    Amount
                  </span>
                  <span className="font-medium text-base mt-4 sm:text-xl sm:mt-6">
                    $10.00
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    <span className="font-medium">1</span> point(s)
                  </span>
                </div>
                {/* ----- Latest Price ----- */}
                <div className="rounded-2xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    Terminology
                  </span>
                  <span className="font-medium text-base mt-4 sm:text-xl sm:mt-6">
                    Points
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    One:{" "}
                    <span className="font-medium text-xs text-green-500 mt-1">
                      Point
                    </span>
                  </span>
                </div>

                {/* -----Items ----- */}
                <div className="rounded-2xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    System
                  </span>
                  <span className="font-medium text-base mt-4 sm:text-xl sm:mt-6">
                    Square
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    Status:{" "}
                    <span className="font-medium text-xs text-green-500 mt-1">
                      Active
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ====================== END HEADER ====================== */}

      <div className="container py-16">
        <Label>Rewards Tiers</Label>
        <div className="text-neutral-500 dark:text-neutral-400 text-sm">
          The list of rewards for buyers.
        </div>
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="flex overflow-auto py-2 space-x-4 customScrollBar">
            {plans.map((plan, index) => (
              <RadioGroup.Option
                key={index}
                value={plan}
                className={({ active, checked }) =>
                  `${active
                    ? "ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60"
                    : ""
                  }
      ${checked
                    ? "bg-teal-600 text-white"
                    : "hover:bg-neutral-100 dark:hover:bg-neutral-800"
                  }
        relative flex-shrink-0 w-44 rounded-xl border border-neutral-200 dark:border-neutral-700 px-6 py-5 cursor-pointer flex focus:outline-none `
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <div className="flex items-center justify-between">
                            <RadioGroup.Description
                              as="div"
                              className={"rounded-full w-16"}
                            >
                              <NcImage
                                containerClassName="aspect-w-1 aspect-h-1 rounded-full overflow-hidden"
                                src={plan.featuredImage}
                              />
                            </RadioGroup.Description>
                            {checked && (
                              <div className="flex-shrink-0 text-white">
                                <CheckIcon className="w-6 h-6" />
                              </div>
                            )}
                          </div>
                          <RadioGroup.Label
                            as="p"
                            className={`font-semibold mt-3  ${
                              checked ? "text-white" : ""
                            }`}
                          >
                            {plan.name}
                            <hr />
                            <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                              <span className="font-medium text-base mt-4 sm:text-xl sm:mt-6">
                                {plan.points}
                              </span>{" "}
                              points
                            </span>
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>

      <div className="container py-16 lg:pb-28 lg:pt-20">
        <main>
          <Heading className="text-neutral-900 dark:text-neutral-50">
            Your Program Memberships
          </Heading>
          <div className="text-neutral-500 dark:text-neutral-400 text-sm space-y-16 lg:space-y-28">
            &nbsp;
          </div>
          {/* FOR DESKTOP */}
          <div className="hidden lg:flex space-x-4">
            {renderTabsAddMembership()}
            {renderTabLocations()}
          </div>

          {/* FOR RESPONSIVE MOBILE */}
          <div className="flex overflow-x-auto lg:hidden space-x-4">
            {renderTabsAddMembership()}
            {renderTabLocations()}
          </div>
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10  mt-8 lg:mt-10">
            {company?.developer?.contracts.map((_, index) => (
              <CardNFT contract={_} key={index} />
            ))}
          </div>
          <ToastContainer limit={3} />
        </main>
      </div>
    </div>
  );
};
function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default DashboardPage;
