import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Template
import ScrollToTop from "./ScrollToTop";
import SiteHeader from "containers/SiteHeader";
import Footer from "shared/Footer/Footer";
//Authentication
import Auth0ProviderWithHistory from "../auth/auth0-provider-with-history";
import PrivateRoute from "../auth/PrivateRoute";
//Pages
import PageLogin from "containers/PageLogin/PageLogin";
import DashboardPage from "containers/DashboardPage/DashboardPage";
import PosPage from "containers/PosPage/PosPage";
import CollectionPage from "containers/CollectionPage/CollectionPage";
const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter basename={"/"}>
      <Auth0ProviderWithHistory>
        <ScrollToTop />
        <SiteHeader />
        <Routes>
          <Route path="/" element={<PageLogin />} />
          <Route path="/dashboard" element={<PrivateRoute element={<DashboardPage />} />} />
          <Route path="/point-of-sale" element={<PrivateRoute element={<PosPage />} />} />
          <Route path="/collection/:contractId" element={<PrivateRoute element={<CollectionPage />} />} />
        </Routes>
   
        <Footer />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
};
export default AppRoutes;