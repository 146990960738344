import Endpoint from "./Endpoint";
import { Invitations, Authorization, User } from "interfaces/Auth0";
export default class Auth0 {
    readonly client_id: string | undefined;
    readonly client_secret: string | undefined;
    readonly url: string | undefined;
    readonly endpoint: Endpoint;
    readonly audience: string | undefined;
    readonly auth0: any;

  
    constructor(...args: [] | [client_id: string | null | undefined, client_secret: string | null | undefined, url: string | null | undefined, audience: string | null | undefined]) {
      this.client_id = args[0] || process.env.REACT_APP_AUTH0_CLIENT_M2MID;
      this.client_secret = args[1] || process.env.REACT_APP_AUTH0_CLIENT_M2MSK;
      this.url = args[2] || process.env.REACT_APP_AUTH0_DOMAIN;
      this.audience = args[3] || `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`;
      this.endpoint = new Endpoint();
    } 
    getInvitationsToOrganization = async (org_id: string) => {
        const Authorization: Authorization = await this.getToken();
        const _inviteResp = await this.endpoint.getOptions(`https://${this.url}/api/v2/organizations/${org_id}/invitations?include_totals=true&include_fields=true&per_page=100`,{
            headers: { 
              'Authorization': `${Authorization.token_type} ${Authorization.access_token}`
            }
          })
        const invitations: Invitations = _inviteResp.data;
        return invitations;
    };
    getInvitationToOrganization = async (org_id: string, invitation: string) => {
        const invitations: Invitations = await this.getInvitationsToOrganization(org_id);
        const _ = require("lodash");
        const _invitation = _.find(invitations.invitations, ['ticket_id', invitation]);
        return _invitation
    };
    createUser = async (_user: User) => {
        const Authorization: Authorization = await this.getToken();
        const userResp = await this.endpoint.putOptions(`https://${this.url}/api/v2/users`, _user,{
            headers: { 
              'Authorization': `${Authorization.token_type} ${Authorization.access_token}`
            }
          })
        const user: User = userResp.data;
        return user;
    };
    getToken = async () => {
        const authResp = await this.endpoint.postExternal(`https://${this.url}/oauth/token`,{client_id: this.client_id, client_secret: this.client_secret, audience: this.audience, grant_type: 'client_credentials' })
        const Authorization: Authorization = authResp.data;
        return Authorization;
    };
  }