import React, { useEffect } from "react";
import { RouteProps, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLogin from "containers/PageLogin/PageLogin";

interface PrivateRouteProps extends Omit<RouteProps, "element"> {
  element: any;
}

function removeParamFromQueryString(url: string, param: string) {
  const urlParts = url.split('?');
  if (urlParts.length <= 1) {
    return url; // no query string
  }
  const queryParams = new URLSearchParams(urlParts[1]);
  queryParams.delete(param);
  const newQueryString = queryParams.toString();
  if (newQueryString === '') {
    return urlParts[0]; // no parameters left, return just the URL
  }
  return `${urlParts[0]}?${newQueryString}`;
}


const PrivateRoute = ({ path, element, ...rest }: PrivateRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    //If the user is using oAuth to connect Square POS
    if(window.location.search.includes('response_type=') === true){
      navigate(`/point-of-sale${removeParamFromQueryString(window.location.search, "state")}`);   
    }

  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }


  return isAuthenticated ? (
    element
  ) : (
    <PageLogin />
  );
};
export default PrivateRoute;