import React, { FC, useState } from "react";
import NcDropDown, { NcDropDownItem } from "shared/NcDropDown/NcDropDown";
import ModalEdit from "./ModalEdit";
import ModalDeveloper from "./ModalDeveloper";

export interface DashboardDropdownProps {
  containerClassName?: string;
  iconClass?: string;
  dropdownPositon?: "up" | "down";
  actions?: { id: string; name: string; icon?: string; href?: string, data: any }[];
}

const actionsDefault: DashboardDropdownProps["actions"] = [
  { id: "report", name: "Report abuse", icon: "las la-flag", data: {} },
];

const DashboardDropdown: FC<DashboardDropdownProps> = ({
  containerClassName = "py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer",
  iconClass = "w-4 h-4 sm:h-5 sm:w-5",
  dropdownPositon = "down",
  actions = actionsDefault,
}) => {
  const [isEditting, setIsEditting] = useState(false);
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [developer, setDeveloper] = useState({});



  const openModalEdit = () => setIsEditting(true);
  const closeModalEdit = () => setIsEditting(false);

  const openModalDeveloper = () => setIsDeveloper(true);
  const closeModalDeveloper = () => setIsDeveloper(false);



  const hanldeClickDropDown = (item: NcDropDownItem) => {
    if (item.href) {
      return;
    }

    if (item.id === "edit") {
      return openModalEdit();
    }
    if (item.id === "developer") {
      setDeveloper(item.data)
      return openModalDeveloper();
    }

    return;
  };

  const renderMenu = () => {
    return (
      <NcDropDown
        className={` ${containerClassName} `}
        iconClass={iconClass}
        data={actions}
        panelMenusClass={
          dropdownPositon === "up"
            ? "origin-bottom-right bottom-0 "
            : "origin-top-right !w-44 sm:!w-52"
        }
        onClick={hanldeClickDropDown}
      />
    );
  };

  return (
    <div className="">
      {renderMenu()}
      <ModalDeveloper
        show={isDeveloper}
        onCloseModalDeveloper={closeModalDeveloper}
        data={developer}
      />
      <ModalEdit show={isEditting} onCloseModalEdit={closeModalEdit} />

    </div>
  );
};
export default DashboardDropdown;