import React from "react";

export interface StatusImageIconProps {
  className?: string;
  active?: boolean;
}

const StatusImageIcon: React.FC<StatusImageIconProps> = ({
  className = "w-8 h-8 md:w-10 md:h-10",
  active = true,
}) => {
  if (active) {
    return (
      <div
        className={`bg-black/50 flex items-center justify-center rounded-full text-white ${className}`}
      >
        <svg
          width="800px"
          height="800px"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          className="iconify iconify--twemoji"
          preserveAspectRatio="xMidYMid meet"
        >
          <circle fill="#78B159" cx="18" cy="18" r="18"></circle>
        </svg>
      </div>
    );
  }
  return (
    <div
      className={`bg-black/50 flex items-center justify-center rounded-full text-white ${className}`}
    >
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        className="iconify iconify--twemoji"
        preserveAspectRatio="xMidYMid meet"
      >
        <circle fill="#DD2E44" cx="18" cy="18" r="18"></circle>
      </svg>
    </div>
  );
};

export default StatusImageIcon;
