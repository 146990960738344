import React from 'react';
import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";
import { useAuth0 } from '@auth0/auth0-react';

const SiteHeader = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated  ? <HeaderLogged /> : <Header2 />;
};

export default SiteHeader;
