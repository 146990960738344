import React, { FC, useEffect, useRef, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";
import Input from "shared/Input/Input";
import DeveloperModalAccordionInfo from "./DeveloperModalAccordionInfo";
export interface ProblemPlan {
  name: string;
  id: string;
  label: string;
}

export interface ModalDeveloperProps {
  data: any;
  show: boolean;
  problemPlans?: ProblemPlan[];
  onCloseModalDeveloper: () => void;
}


const ModalDeveloper: FC<ModalDeveloperProps> = ({
  data,
  show,
  onCloseModalDeveloper,
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
    }
  }, [show]);

  const handleClickSubmitForm = () => {};



  const renderContent = () => {
    return (
      <form action="#">

                {/* TEXAREA MESSAGER */}
                <div className="mt-4">
       
       <h4 className="text-lg font-semibold text-neutral-700 dark:text-neutral-200">
         Your Wallets
       </h4>
       <span className="text-sm text-neutral-6000 dark:text-neutral-400">
         These are the wallets that own and control your digital asset collections.
       </span>

     </div>

        <div className="mt-8 relative rounded-md shadow-sm">
          <Input ref={textareaRef} defaultValue={typeof data.backpacks !== "undefined" ? data.backpacks[0].address : ""} type={"text"} disabled={true}/>

          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="wallet" className="sr-only">
              Wallet
            </label>
            <select
              id="wallet"
              name="wallet"
              className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-neutral-500 dark:text-neutral-300 sm:text-sm rounded-md"
            >
              <option>POLYGON WALLET</option>
              {/* <option>ETH WALLET</option> */}
            </select>
          </div>
        </div>

        {/* TEXAREA MESSAGER */}
        <div className="mt-4">
       
          <h4 className="text-lg font-semibold text-neutral-700 dark:text-neutral-200">
            Your API Key(s)
          </h4>
          <span className="text-sm text-neutral-6000 dark:text-neutral-400">
            These are the keys that you can use to access Backpac's APIs on behalf of your wallets.
          </span>

        </div>
        <div className="mt-4 space-x-3">
          <DeveloperModalAccordionInfo keys={data.keys} />
        </div>
        <div className="mt-4 space-x-3">
          {/* <ButtonPrimary onClick={handleClickSubmitForm} type="submit">
            Submit
          </ButtonPrimary> */}
          <ButtonSecondary type="button" onClick={onCloseModalDeveloper}>
            Cancel
          </ButtonSecondary>
        </div>
      </form>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalDeveloper}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Developer Details"
    />
  );
};

export default ModalDeveloper;
