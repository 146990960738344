import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

export default function DeveloperModalAccordionInfo(keys: any) {
if(Array.isArray(keys.keys) === true){
  return (
    <div className="w-full rounded-2xl">
      {keys.keys.map(function (item: any, i: number) {
        return (
          <Disclosure defaultOpen={false}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                  <span>API Key</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-neutral-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel
                  className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
                  as="p"
                >
                  <span>Username:</span>
                  <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1">
                    {item.username}
                  </span>
                  <span>Key:</span>
                  <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1">
                  {item.key}
                  </span>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      })}
    </div>
  );
}else{
  return (
    <div className="w-full rounded-2xl">
      Loading
    </div>
  );
}
}