import Endpoint from "./Endpoint";
export default class Square {
    readonly url: string | undefined;
    readonly endpoint: Endpoint;

    constructor(...args: [] | [url: string | null | undefined]) {
      this.url = args[0] || process.env.REACT_APP_CONSOLE_API_URL;
      this.endpoint = new Endpoint();
    } 
    authorize = async () => {
      const _Endpoint = new Endpoint();
      const authUrl = await _Endpoint.get('/square/oAuth/authorize');
      return authUrl;
    };
    obtainToken = async (companyuuid: string, code: string) => {
      const _Endpoint = new Endpoint();
      const token = await _Endpoint.post('/square/oAuth/obtainToken', {companyuuid, code});
      console.log(token);
      return token;
    };
  }