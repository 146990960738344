import React, { FC, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Helmet } from "react-helmet";
import { useAuth0 } from "@auth0/auth0-react";
import Square from "model/Square";
import Merchant from "model/Merchant";
import { ToastContainer, toast, ToastOptions, Id } from "react-toastify";
export interface PosPageProps {
  className?: string;
}


const PosPage: FC<PosPageProps> = ({ className = "" }) => {
  const { user } = useAuth0();
  const [companyuuid, setCompanyuuid] = useState<string>("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (user !== null && typeof user !== "undefined") {
      setCompanyuuid(
        user[process.env.REACT_APP_AUTH0_USER_METADATA || ""].companyUUID
      );

      

      if(searchParams.get("code") !== null){
        console.log(searchParams.get("code"));
        const code = searchParams.get("code");
        obtainToken(user[process.env.REACT_APP_AUTH0_USER_METADATA || ""].companyUUID, code || "")
      }

    }
  }, [user, searchParams]);


  useEffect(() => {}, [companyuuid]);

  async function authorize() {
    const square = new Square();
    const { data } = await square.authorize();
    window.location.href = data.data;
  }

  async function obtainToken(companyuuid: string, code: string) {
    const square = new Square();
    const { data } = await square.obtainToken(companyuuid, code);
    console.log(data);
  }

  async function getMerchant(companyuuid: string) {
    const merchant = new Merchant();
    
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Point of Sale Settings || Backpac Inc</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Point of Sale settings
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set manage your POS connections and settings.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
              <ButtonSecondary
                className="w-full"
                onClick={(e) => {
                  e.preventDefault();

                  authorize();
                }}
              >
                Connect Square
              </ButtonSecondary>
              <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
              <ButtonSecondary className="w-full">Connect Toast</ButtonSecondary>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={3} />
    </div>
  );
};
export default PosPage;
